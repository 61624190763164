<template>
  <div class="PageBox all">
    <van-nav-bar
        fixed
        :border="false"
        :title="$t('invite[0]')"
        left-arrow
        @click-left="$router.go(-1)"
    />
    <div class="activity">
      <div style="margin: 0 auto;text-align: center;line-height: 25px;font-size: 18px">
        <div class="xxxx" v-if="!show_idcode"></div>
        <div v-show="show_idcode">
          <div class="title_code" style="color: #fff">{{$t("invite[1]")}}</div>
          <div class="text_code" style="color: #fff;margin: 10px">{{idcode}}</div>

          <div class="QRCode" id="qrcode"/>
          <div class="text_desc">{{$t("invite[2]")}}</div>
        </div>
      </div>
      <div class="centerPanel" style="margin-top: 0px">
        <van-button v-show="show_idcode" @click="copyCode">{{ $t('invite[3]') }}</van-button>
      </div>
    </div>

    <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>

  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { Toast, Dialog } from "vant";
import config from "@/config";

var c;
export default {
  data() {
    return {
      //show  shows
      chenggong: false, //  领取成功时的弹窗
      shibai: false, // 不能领取时的弹窗
      inviteInfo: {
        sure_receive_money: "",
        item: {
          cover_img: "",
        },
      }, //保存邀请数据

      flag: true, //判断是否领取过
      vip_level: "", //vip等级
      invalid_receive_money: 0,
      award_model: 0, //模式
      agent_type:0,
      showAddress:true,
      inviteUrl:'',
      idcode:null,
      show_idcode:false,
      qrcode:null,
    };
  },
  props: ["id", "sign_id"],
  created() {
    this.inviteUrl = config.ApiUrl+"/#/register/"+this.UserInfo.idcode;
    this.idcode = this.UserInfo.idcode;
    let level = this.UserInfo.vip_level;
    // this.show_idcode = true;
    if (level>=3){
      this.show_idcode = true;
    }
  },
  mounted() {
    var that = this;
    this.$Model.GetUserInfo(data=>{
      if (data.code==1){
        that.agent_type = data.info['agent_type']
        that.doShow();
      }
    })

  },
  computed: {
    //领取按钮
    receive() {
      if (this.inviteInfo.sure_receive_money == 0) {
        return "sem quantia"; //余额不足
      }
      if (this.inviteInfo.sure_receive_money != 0) {
        return "Receber"; //领取
      } else if (this.flag === false) {
        return "Já recebido hoje"; //今日已领取
      }
    },
    //活动规则
    explain() {
      if (this.inviteInfo) {
        return this.inviteInfo.item.explain;
      } else {
        return "<p></p>";
      }
    },
  },
  filters: {
    只显示小数后两位: function (n) {
      return n.substr(0, n.indexOf(".") + 3);
    },
  },
  methods: {
    //复制码和金额
    copyCode() {
      var copyBox = document.getElementById("inputBox");
      copyBox.value = this.inviteUrl;
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t('recharge.label[5]'));
    },
    toWallet() {
      this.$router.push("/user/wallet");
    },
    toPromote() {
      this.$router.push("/user/promote");
    },
    doShow(){
      if (!this.qrcode){
        var codeElement = document.getElementById("qrcode");
        this.qrcode = new QRCode(codeElement, {
          text: this.inviteUrl,
          width: 160,
          height: 160,
          colorDark : "#000000",
          colorLight : "#ffffff",
          style:"border:1px solid",
          correctLevel : QRCode.CorrectLevel.H
        });
        let b = 100;
        // new QRCode(codeElement, this.InitData.setting.service_url);
      }
    },
  },
};
</script>
<style scoped>
.PageBox >>> .van-nav-bar__title{
  color:#FFFFFF;
  font-size: 18px;
}
.PageBox >>> .van-nav-bar .van-nav-bar__arrow {
  font-size: 20px;
  color:#FFFFFF;
}

.all {
  min-height: 100vh;
  background-color: var(--main-bg-color);
}

.activity {
  width: calc(100% - 40px);
  height: 400px;
  position: absolute;
  margin-left: 20px;
  color:var(--font_subtitle);
  border-radius: 10px;
  padding-top: 105px;
}
.title_code{
  height: 46px;
  line-height: 46px;
  color: #fff;
}
.text_code{
  color: #005FFB;
  font-size: 28px;
  font-weight: 600;
}

.text_desc {
  margin: 10px;
  margin-top: 30px;
  font-size: 15px;
}

.cover_img {
  width: 100%;
  height: auto;
}
.cover_img > img {
  width: 100%;
  height: auto;
  display: block;
}
.inviteTit {
  width: 220px;
  height: 125px;
  /* background:pink; */
  position: absolute;
  top: 130px;
  left: 80px;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
}
.inviteTit > p:first-child {
  color: #fff;
  font-size: 18px;
  line-height: 20px;
}
.inviteTit > p:last-child {
  color: rgb(0, 255, 255);
  font-size: 25px;
  line-height: 30px;
}

.QRCode {
  margin: 15px auto;
  height: 140px;
  width: 140px;
  text-align: center;
}
.xxxx{
  margin: 140px 0;
}

.successImg > img {
  width: 100%;
  height: 100%;
}

</style>
